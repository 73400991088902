<template>
  <div class="bus-panel">
    <div class="top-side">
      <img class="icon" src="@/assets/img/bus.svg">
      <div class="info">
        <p class="info__name-route">{{ infoRoutes[0].no }}</p>
        <p class="info__routes">{{ infoRoutes[0].short_name }}</p>
      </div>
    </div>
    <div class="middle-side">
      <!-- <div class="info">
        <img src="@/assets/img/busStation.svg">
        <p class="info__name-bus-station">Аллея памяти и славы</p>
        <p class="info__arrive-time">через 5 минут</p>
      </div> -->
      <div class="route-path">
        <routes-line
          :routes="properties.scheduleStationRoutes"
          class="selected-route__routes-line"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RoutesLine from "@/components/Parts/RoutesLine";

export default {
  components: {
    RoutesLine
  },

  props: {
    properties: {
      type: Object,
      default: () => ({})
    },
    bus: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {

    }
  },
  computed: {
    infoRoutes() {
      let routes =  this.properties.getStop.direct_routes.concat(this.properties.getStop.backward_routes);
      return routes.filter(el => el.id === this.bus.routeId);
    }
  }
}
</script>

<style lang="scss" scoped>
.bus-panel{
  width: 100%;
}

.top-side {
  display: flex;
  padding-bottom: 32px;
  margin: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);


  .icon {
    width: 64px;
    height: 64px;
    margin-right: 24px;
  }

  .info {
    &__name-route {
      font-size: 54px;
      line-height: 64px;
    }

    &__routes {
      font-size: 30px;
      line-height: 36px;
      color: #fff;
      opacity: 0.7;
    }
  }
}

.middle-side {
  margin-top: 40px;
  margin-left: 32px;

  .route-path {
    .swiper-slide {
      &:first-child {
        margin-left: 32px;
      }
    }
  }
}
</style>