<template>
  <div class="routes-line-block">
    <div class="bus-station">
      <div class="bus-station__container">
        <img src="@/assets/img/busStation.svg" />
        <div class="info-station">
          <h2> {{ stopName }} </h2>
        </div>
      </div>

      <div class="bus-station__prediction-time">
        <p v-if="parseInt(getPredictionTime) === 0" class="card__bus-arrival">
          <img src="@/assets/img/busCircle.svg"/>
          прибыл
        </p>

        <p v-if="parseInt(getPredictionTime) === 1" class="card__bus-arrival">
          <img src="@/assets/img/busCircle.svg"/>
          прибывает
        </p>

        <p v-if="parseInt(getPredictionTime) > 1" class="card__bus-arrival">
          через {{ getPredictionTime }} мин
        </p>

        <p v-if="getPredictionTime === null">
          данные подгружаются
        </p>
      </div>
    </div>

    <swiper
      class="swiper swiper-station"
      :options="{
        spaceBetween: 0,
        freeMode: true,
        speed: 50,
        width: 315,
      }"
    >
      <swiper-slide
        v-for="(item, index) in routes"
        :key="index"
        class="station"
        @click.native="clickStation(item)"
      >
        <div class="name-station">
          {{ item.name }}
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

export default {
  name: "RoutesLine",
  props: {
    routes: {
      type: Array,
      default: () => ([])
    },
    stopName: {
      type: String,
    },
    currentStopRoutes: {
      type: [Object, Array]
    },
    activeRoute: {
      type: [String, Number]
    }
  },

  computed: {
    getPredictionTime: function() {
      const currentRoute = this.currentStopRoutes.filter(item => parseInt(item.no) === parseInt(this.currentRoute));

      if (currentRoute[0] !== undefined) {
        return currentRoute[0].prediction;
      }

      return null;
    }
  },

  data: function() {
    return {
      currentRoute: this.activeRoute
    }
  },

  methods: {
    clickStation(busStation) {
      this.$emit("switch-bus-station", busStation);
    }
  },

  watch: {
    activeRoute: function(newVal) {
      this.currentRoute = newVal;
    }
  }
}
</script>

<style lang="scss">
.swiper-station {
  height: 129px;
}

.swiper-wrapper {
  .station:last-of-type {
    &:after {
      opacity: 0;
    }
  }
}

.routes-line-block {
  .bus-station {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    width: 1016px;

    padding: 32px 0 32px;
    border-top: 3px solid rgba(255, 255, 255, 0.16);

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .info-station {
      padding-left: 24px;

      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 35px;
        line-height: 36px;
        color: #fff;
      }
    }

    &__prediction-time {
      font-weight: 500;
      font-size: 35px;
      line-height: 36px;
      color: #fff;

      margin-left: auto;
    }
  }
}

.station {
  width: 360px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  .name-station {
    width: 100%;
    height: 150px;
    padding-top: 33px;
    font-size: 30px;
    line-height: 36px;
  }

  &:before, &:after {
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px;
    content: "";
    height: 15px;
    background-color: #fff;
    border: 6px solid #979797;
    border-radius: 50%;
  }

  &:after {
    top: 0;
    left: 25px;
    width: 100%;
    height: 10px;
    margin-top: 12px;
    background-color: #1a8bff;
    border: 0;
    border-radius: 0;
  }
}
</style>
