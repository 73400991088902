<template>
  <div class="transport-keyboard">
    <!-- <delete-button-keyboard class="keyboard__key--delete" /> -->
  </div>
</template>

<script>
import DeleteButtonKeyboard from "@/components/Icons/DeleteButtonKeyboard";

export default {
  name: "Keyboard",
  components: {
    DeleteButtonKeyboard
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    focused: {
      type: Boolean,
      default: false
    },
    isKeyboard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyboard: {
        elements: {
          main: null,
          keysContainer: null,
          keys: []
        },

        eventHandlers: {
          oninput: null,
          onclose: null
        },

        properties: {
          value: "",
          capsLock: false
        },
      }
    }
  },

  watch: {
    focused(newValue) {
      if (newValue === false) {
        this.close()
      }
    },
    isKeyboard(newValue) {
      if(!newValue) {
        this.close();
      }
    }
  },

  mounted() {
    this.initKeyboard();
  },

  methods: {
    initKeyboard() {
      this.keyboard.elements.main = document.createElement("div");
      this.keyboard.elements.keysContainer = document.createElement("div");

      this.keyboard.elements.main.classList.add("keyboard", "keyboard--hidden");

      this.keyboard.elements.keysContainer.classList.add("keyboard__keys");
      this.keyboard.elements.keysContainer.appendChild(this.createKeyboard());

      this.keyboard.elements.keys = this.keyboard.elements.keysContainer.querySelectorAll(".keyboard__key");

      this.keyboard.elements.main.appendChild(this.keyboard.elements.keysContainer);
      const divKeybord = document.querySelector(".transport-keyboard");
      divKeybord.appendChild(this.keyboard.elements.main)

      document.querySelectorAll(".transport-card__search-input").forEach(element => {
        element.addEventListener("focus", () => {
          this.open(element.value, currentValue => {
            element.value = currentValue;
          });
        });
      });
    },

    createKeyboard() {
      const fragment = document.createDocumentFragment();
      const keyLayout = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "backspace", "й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э", "я", "ч", "с", "м", "и", "т", "ь", "ъ", "б", "ю", "ё",
        "space"
      ]
      const keyNumber = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
      const deleteIcon = new Image();
      deleteIcon.src = require("@/assets/img/delete.svg");

      keyLayout.forEach(key => {
        const keyElement = document.createElement("button");
        const insertLineBreak = ["backspace", "х", "э", "ё"].indexOf(key) !== -1;

        keyElement.setAttribute("type", "button");
        keyElement.classList.add("keyboard__key");
        
        keyNumber.forEach(keyNumber => {
          if (key === keyNumber) {
            keyElement.classList.add("keyboard__key--number")
          }
        })

        switch (key) {
          case("backspace"):
            keyElement.appendChild(deleteIcon)
            keyElement.classList.add("keyboard__key-wide");
            keyElement.addEventListener("click", () => {
              this.$emit("delete-symbol", this.value.substring(0, this.value.length - 1))
            });
            break;

          case("space"):
            keyElement.classList.add("keyboard__key--space")
            keyElement.addEventListener("click", () => {
              this.$emit("add-space"," ")
              this.triggerEvent("oninput");
            });
            break;

          default:
            keyElement.textContent = key.toLowerCase();
            keyElement.addEventListener("click", () => {
              this.$emit("change-value", keyElement.textContent)
          })
        }

        fragment.appendChild(keyElement)

        if (insertLineBreak) {
          fragment.appendChild(document.createElement("br"));
        }

      });

      return fragment;
    },

    triggerEvent(handlerName) {
      if (typeof this.keyboard.eventHandlers[handlerName] === "function") {
        this.keyboard.eventHandlers[handlerName](this.keyboard.properties.value);
      }
    },

    open(initialValue, oninput, onclose) {
      this.keyboard.properties.value = initialValue || "";
      this.keyboard.eventHandlers.oninput = oninput;
      this.keyboard.eventHandlers.onclose = onclose;
      this.keyboard.elements.main.classList.remove("keyboard--hidden");
      this.$emit("show-keyboard", true);
    },

    close() {
      this.keyboard.properties.value = "";
      this.keyboard.eventHandlers.oninput = oninput;
      this.keyboard.eventHandlers.onclose = onclose;
      this.keyboard.elements.main.classList.add("keyboard--hidden");
    }
  }
}
</script>

<style lang="scss">
.keyboard {
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  user-select: none;
  transition: bottom 0.4s;

  &__key {
    height: 63px;
    width: 83px;
    max-width: 90px;
    margin: 6px;
    border-radius: 6px;
    border: none;
    background: #464646;
    color: #d9dbdc;
    font-size: 28px;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    padding: 0;
    position: relative;

    &--number {
      color: #404b50;
      background-color: #d9dbdc;
    }

    &--space {
      width: 663px;
      max-width: 100%;
    }

    &--delete {
      display: none;
    }

    &:active {
      background: rgba(255, 255, 255, 0.12);
    }

    &--wide {
      width: 12%;
    }

    &--extra-wide {
      width: 36%;
      max-width: 500px;
    }
  }


  &--hidden {
    display: none;
  }

  &__keys {
    text-align: center;
  }
}

.keyboard__key--activatable::after {
    content: '';
    top: 10px;
    right: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
}

.keyboard__key--active::after {
    background: #08ff00;
}

.keyboard__key--dark {
    background: rgba(0, 0, 0, 0.25);
}

</style>