var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bus-station"},[_c('div',{staticClass:"top-side"},[_c('div',{staticClass:"top-side__left-side"},[_c('img',{attrs:{"src":require("@/assets/img/busStation.svg")}}),_c('div',{staticClass:"info-station"},[_c('h2',{staticClass:"info-station__title-bus"},[_vm._v(" "+_vm._s(_vm.properties.currentStation.code || _vm.properties.currentStation.name)+" ")]),_c('p',{staticClass:"info-station__bus-route"},[_vm._v(" "+_vm._s(_vm.properties.currentStation.landmark)+" ")])])]),_c('div',{staticClass:"top-side__right-side"})]),_c('div',{staticClass:"bottom-side"},[_c('div',{staticClass:"bus-cards",class:_vm.busInfo.length > 4 ? 'slide' : ''},[_c('swiper',{attrs:{"options":{
          slidesPerView: 2,
          touchRatio: 2,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'column',
          slidesPerGroup: 2,
        }}},_vm._l((_vm.busInfo),function(item,index){return _c('swiper-slide',{key:index,nativeOn:{"click":function($event){return _vm.goStopBus(item)}}},[_c('bus-card',{attrs:{"bus-card":item}})],1)}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }