<template>
  <div
    class="events-mini-card"
    :style="{ width: width + 'px' }"
  >
    <img :src="event.images[0]" class="events-mini-card__img" />
    <p class="events-mini-card__title">
      {{ sliceTitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    path: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 292
    }
  },
  computed: {
    sliceTitle() {
      let maxLength = 13;
      return this.event.title.slice(0, maxLength) + "...";
    }
  }
}
</script>

<style lang="scss" scoped>
.events-mini-card {
  position: relative;
  width: 219px;
  height: 292px;
  border-radius: 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 57.31%, rgba(0, 0, 0, 0.8) 100%);

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    opacity: 0.6;
  }

  &__title {
    position: absolute;
    padding: 0 12px 12px 12px;
    bottom: 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #fff;
  }
}
</style>