<template>
  <div class="bus-station">
    <div class="top-side">
      <div class="top-side__left-side">
        <img src="@/assets/img/busStation.svg" />
        <div class="info-station">
          <p>Остановка</p>
          <h2>Магазин Юбилейный</h2>
        </div>
      </div>
      <div class="top-side__right-side">
        <button
          class="build-btn"
        >
          Построить маршрут
        </button>
      </div>
    </div>

    <div 
      class="bus-cards"
    >
      <bus-card 
        :bus-cards="busInfo"
      />
    </div>
  </div>
</template>

<script>
import BusCard from "@/components/Parts/BusCard";

export default {
  name: "BusStop",
  components: {
    BusCard
  },
  data() {
    return {
      busInfo: [
        {
          busNumber: "T11",
          busStation: "Моховая",
          busArrival: 2
        },
        {
          busNumber: "A321",
          busStation: "Беговая",
          busArrival: 6
        },
        {
          busNumber: "17",
          busStation: "Ховрино",
          busArrival: 3
        },
        {
          busNumber: "T6",
          busStation: "Фили",
          busArrival: 1
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.bus-station {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 30px;

  .info-station {
    padding-left: 19px;

    p {
      font-size: 20px;
      font-weight: normal;
      color: rgba(#fff, 0.7);
      text-transform: uppercase;
    }
    
    h2 {
      margin: 0;
      font-size: 54px;
      font-weight: normal;
      line-height: 60px;
    }
  }

  .bus-cards {
    margin-top: 32px;
  }
  
  .top-side {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__left-side {
      display: flex;
    }

    &__right-side {
      .build-btn {
        width: 238px;
        padding: 15px 20px;
        color: #fff;
        font-size: 30px;
        font-weight: normal;
        line-height: 32px;
        border: 0;
        border-radius: 100px;
        background: linear-gradient(180deg, #62AFFF 0%, #0B83FF 105.9%);
      }
    }
  }
}
</style>